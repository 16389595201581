
import { _support, setSilentFlag, BASETYPE,OPTIONS,validateOption ,InitOptions } from '../utils';

import { reportData } from './report';

export class Options {
  dsn = ''; // 接口的地址
  throttleDelayTime = 0; // click事件的节流时长
  overTime = 10;
  filterXhrUrlRegExp: any; // 过滤的接口请求正则
  handleHttpStatus: any; // 处理接口返回的 response
  repeatCodeError = false;
  constructor() { }


  bindOptions(options: InitOptions): void {
    const {
      dsn,
      filterXhrUrlRegExp,
      throttleDelayTime = 0,
      overTime = 10,
      handleHttpStatus,
      repeatCodeError = false,
    } = options;
    validateOption(dsn, OPTIONS.DNS, BASETYPE.STRING) && (this.dsn = dsn);
    validateOption(throttleDelayTime, OPTIONS.THROTTLEDELAYTIME, BASETYPE.NUMBER) &&
      (this.throttleDelayTime = throttleDelayTime);
    validateOption(overTime, OPTIONS.OVERTIME, BASETYPE.NUMBER) && (this.overTime = overTime);
    validateOption(filterXhrUrlRegExp, OPTIONS.FILTERXHRURLREGEXP, BASETYPE.REGEXP) &&
      (this.filterXhrUrlRegExp = filterXhrUrlRegExp);
    validateOption(handleHttpStatus, OPTIONS.HANDLEHTTPSTATUS, BASETYPE.FUNCTION) &&
      (this.handleHttpStatus = handleHttpStatus);
    validateOption(repeatCodeError, OPTIONS.REPEATCODEERROR, BASETYPE.BOOLEAN) &&
      (this.repeatCodeError = repeatCodeError);
  }
}
export const options = _support.options || (_support.options = new Options());

export function handleOptions(paramOptions: InitOptions): void {
  setSilentFlag(paramOptions);
  /**验证数据格式是否正确 */
  reportData.bindOptions(paramOptions);
  options.bindOptions(paramOptions);
}

