


import { Callback } from '../utils';
import {SDK_NAME} from './util'




export function typeofAny(target: any): string {
    return Object.prototype.toString.call(target).slice(8, -1).toLowerCase();
  }
//生成唯一的编码
export function getErrorUid(input: string): string {
    return window.btoa(encodeURIComponent(input));
  }

export function unknownToString(target: unknown): string {
  if (variableTypeDetection.isString(target)) {
    return target as string;
  }
  if (variableTypeDetection.isUndefined(target)) {
    return 'undefined';
  }
  return JSON.stringify(target);
}

/**
 * 函数节流
 */
export const throttle = (fn: any, delay: number) => {
    let canRun = true;
    return function (this: any, ...args: any[]) {
      if (!canRun) return;
      fn.apply(this, args);
      canRun = false;
      setTimeout(() => {
        canRun = true;
      }, delay);
    };
  };
/**
 * 添加事件监听器
 */
export function on(target: any, eventName: string, handler: Callback, opitons = false) {
    target.addEventListener(eventName, handler, opitons);
  }

export function interceptStr(str: string, interceptLength: number): string {
    if (variableTypeDetection.isString(str)) {
      return (
        str.slice(0, interceptLength) +
        (str.length > interceptLength ? `:截取前${interceptLength}个字符` : '')
      );
    }
    return '';
  }


// 获取当前的时间戳
export function getTimestamp(): number {
    return Date.now();
  }

// 验证选项的类型
export function validateOption(target: any, targetName: string, expectType: string): any {
    if (!target) return false;
    if (typeofAny(target) === expectType) return true;
    console.error(`${SDK_NAME}: ${targetName}需要传入${expectType}类型，目前是${typeofAny(target)}类型`);
  }

export function getLocationHref(): string {
    if (typeof document === 'undefined' || document.location == null) return '';
    return document.location.href;
  }

function isType(type: any) {
    return function (value: any): boolean {
      return Object.prototype.toString.call(value) === `[object ${type}]`;
    };
  }

  export const variableTypeDetection = {
    isNumber: isType('Number'),
    isString: isType('String'),
    isBoolean: isType('Boolean'),
    isNull: isType('Null'),
    isUndefined: isType('Undefined'),
    isSymbol: isType('Symbol'),
    isFunction: isType('Function'),
    isObject: isType('Object'),
    isArray: isType('Array'),
    isProcess: isType('process'),
    isWindow: isType('Window'),
  };

  export function isError(error: Error): boolean {
    switch (Object.prototype.toString.call(error)) {
      case '[object Error]':
        return true;
      case '[object Exception]':
        return true;
      case '[object DOMException]':
        return true;
      default:
        return false;
    }
  }

  export function isEmptyObject(obj: object): boolean {
    return variableTypeDetection.isObject(obj) && Object.keys(obj).length === 0;
  }
  export function isEmpty(wat: any): boolean {
    return (
      (variableTypeDetection.isString(wat) && wat.trim() === '') || wat === undefined || wat === null
    );
  }

  export function isExistProperty(obj: any, key: any): boolean {
    return Object.prototype.hasOwnProperty.call(obj, key);
  }

  export function generateUUID(): string {
    let d = new Date().getTime();
    const uuid = 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
      const r = (d + Math.random() * 16) % 16 | 0;
      d = Math.floor(d / 16);
      return (c == 'x' ? r : (r & 0x3) | 0x8).toString(16);
    });
    return uuid;
  }
