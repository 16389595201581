import ErrorStackParser from 'error-stack-parser';
import { getErrorUid, getTimestamp, unknownToString } from '../utils';
import {
  reportData,
  resourceTransform,
  httpTransform,
  options,
} from './index';
import {
  hashMapExist,
  EVENTTYPES, STATUS_CODE,
  getFlag, nativeTryCatch, setFlag,

  ErrorTarget,
  HttpData,
  ReplaceHandler,
  ReplaceCallback
} from '../utils';



import { addRewriteHandler } from './replace';




export const Events = {
  handleHttp(data: HttpData, type: EVENTTYPES): void {
    const result = httpTransform(data);
    // @ts-ignore
    if (result.status === STATUS_CODE.ERROR) {

      reportData.send({ ...result, type, status: STATUS_CODE.ERROR });
    }
  },
  handleError(ev: ErrorTarget): void {
    const target = ev.target;
    if (!target || (ev.target && !ev.target.localName)) {
      const stackFrame = ErrorStackParser.parse(!target ? ev : ev.error)[0];
      const { fileName, columnNumber, lineNumber } = stackFrame;
      const errorData = {
        type: EVENTTYPES.ERROR,
        status: STATUS_CODE.ERROR,
        time: getTimestamp(),
        message: ev.message,
        fileName,
        lineName: lineNumber,
        columnName: columnNumber,
      };

      const hash: string = getErrorUid(
        `${EVENTTYPES.ERROR}-${ev.message}-${fileName}-${columnNumber}`
      );

      if (!options.repeatCodeError || (options.repeatCodeError && !hashMapExist(hash))) {
        return reportData.send(errorData);
      }
    }

    // 资源加载报错
    if (target?.localName) {
      const data = resourceTransform(target);
      return reportData.send({
        ...data,
        type: EVENTTYPES.RESOURCE,
        status: STATUS_CODE.ERROR,
      });
    }
  },

  handleUnhandleRejection(ev: PromiseRejectionEvent): void {
    const stackFrame = ErrorStackParser.parse(ev.reason)[0];
    const { fileName, columnNumber, lineNumber } = stackFrame;
    const message = unknownToString(ev.reason.message || ev.reason.stack);
    const data = {
      type: EVENTTYPES.UNHANDLEDREJECTION,
      status: STATUS_CODE.ERROR,
      time: getTimestamp(),
      message,
      fileName,
      lineName: lineNumber,
      columnName: columnNumber,
    };
    const hash: string = getErrorUid(
      `${EVENTTYPES.UNHANDLEDREJECTION}-${message}-${fileName}-${columnNumber}`
    );
    if (!options.repeatCodeError || (options.repeatCodeError && !hashMapExist(hash))) {
      reportData.send(data);
    }
  },

};



const handlers: { [key in EVENTTYPES]?: ReplaceCallback[] } = {};


export function subscribeEvent(handler: ReplaceHandler): boolean {
  if (!handler || getFlag(handler.type)) return false;
  setFlag(handler.type, true);
  handlers[handler.type] = handlers[handler.type] || [];
  handlers[handler.type]?.push(handler.callback);
  return true;
}



export function notify(type: EVENTTYPES, data?: any): void {
  if (!type || !handlers[type]) return;
  handlers[type]?.forEach(callback => {
    nativeTryCatch(
      () => {
        callback(data);
      },
      (err:string) => {
        console.error(err)
      }
    );
  });
}


export function rewriteEvent(): void {

  addRewriteHandler({
    callback: data => {
      Events.handleHttp(data, EVENTTYPES.XHR);
    },
    type: EVENTTYPES.XHR,
  });

  addRewriteHandler({
    callback: data => {
      Events.handleHttp(data, EVENTTYPES.FETCH);
    },
    type: EVENTTYPES.FETCH,
  });
  // 捕获错误
  addRewriteHandler({
    callback: error => {
      Events.handleError(error);
    },
    type: EVENTTYPES.ERROR,
  });
  addRewriteHandler({
    callback: data => {
      Events.handleUnhandleRejection(data);
    },
    type: EVENTTYPES.UNHANDLEDREJECTION,
  });
}
