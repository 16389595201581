import {
  handleOptions,
  rewriteEvent,
  Events,
} from './core/index';
import { _global, getFlag, setFlag,EVENTTYPES,SDK_NAME,OPTIONS,InitOptions, VueInstance, ViewModel } from './utils';


function init(options: InitOptions) {
  if (!options.dsn || !options.sysCode) {
    return console.error(`${SDK_NAME} 缺少：${!options.dsn ? OPTIONS.DNS : OPTIONS.APIKEY} `);
  }
  if (!(EVENTTYPES.FETCH in _global) || options.disabled) return;

  handleOptions(options);
  rewriteEvent();
}

function install(Vue: VueInstance, options: InitOptions) {
  if (getFlag(EVENTTYPES.VUE)) return;
  setFlag(EVENTTYPES.VUE, true);
   init(options);
  const handler = Vue.config.errorHandler;
  Vue.config.errorHandler = function (err: Error, vm: ViewModel, info: string): void {
    Events.handleError(err);
    if (handler) handler.apply(null, [err, vm, info]);
  };

}




export default {
  init,
  install,
};
