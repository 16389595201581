import { _global } from './global';
import { voidFun } from '../utils';
import { BASETYPE,OTHERTYPE } from './enum';

export class Queue {
  private stack: any[] = [];
  private isFlushing = false;
  constructor() {}
  addFn(fn: voidFun): void {
    if (typeof fn !== BASETYPE.FUNCTION) return;
    if (!(OTHERTYPE.REQUESTIDLECALLBACK in _global || OTHERTYPE.PROMISE in _global)) {
      fn();
      return;
    }
    this.stack.push(fn);
    if (!this.isFlushing) {
      this.isFlushing = true;

      if (OTHERTYPE.REQUESTIDLECALLBACK in _global) {
        requestIdleCallback(() => this.flushStack());
      } else {

        Promise.resolve().then(() => this.flushStack());
      }
    }
  }
  clear() {
    this.stack = [];
  }
  getStack() {
    return this.stack;
  }
  flushStack(): void {
    const temp = this.stack.slice(0);
    this.stack = [];
    this.isFlushing = false;
    for (let i = 0; i < temp.length; i++) {
      temp[i]();
    }
  }
}
