
import {
  _support,
  isBrowserEnv,
  Queue,
  SDK_VERSION,
  SDK_NAME,
  BASETYPE,
  EMethods,
  OTHERTYPE,
  OPTIONS,
  isEmpty,generateUUID,getLocationHref,validateOption,
  ReportData, InitOptions
} from '../utils';






export class Report {
  queue: Queue = new Queue(); // 消息队列
  sysCode = '';
  appCode: any;
  errorDsn = '';
  userId = '';
  uuid: string; // 每次页面加载的唯一标识
  beforeDataReport: any; // 上报数据前的hook
  getUserId: any; // 用户自定义获取userId的方法
  useImgUpload = false;
  constructor() {
    this.uuid = generateUUID();
  }
  beacon(url: string, data: any): boolean {
    return navigator.sendBeacon(url, JSON.stringify(data));
  }
  imgRequest(data: ReportData, url: string): void {
    const requestFun = () => {
      const img = new Image();
      const spliceStr = url.indexOf('?') === -1 ? '?' : '&';
      img.src = `${url}${spliceStr}data=${encodeURIComponent(JSON.stringify(data))}`;
    };
    this.queue.addFn(requestFun);
  }

  async beforePost(this: any, data: ReportData): Promise<ReportData | boolean> {
    let reportData = this.getTransportData(data);
    if (typeof this.beforeDataReport === BASETYPE.FUNCTION) {
      reportData = this.beforeDataReport(reportData);
      if (!reportData) return false;
    }
    return reportData;
  }
  async xhrPost(data: ReportData, url: string): Promise<void> {
    const requestFun = () => {
      fetch(`${url}`, {
        method: EMethods.Post,
        body: JSON.stringify(data),
        headers: {
          'Content-Type': OTHERTYPE.CONTENTTYPE,
        },
      });
    };
    this.queue.addFn(requestFun);
  }
  // 获取用户信息
  getAuthInfo() {
    return {
      userId: this.userId || this.getAuthId() || '',
      sdkVersion: SDK_VERSION,
      sysCode: this.sysCode,
      appCode: this.appCode,
    };
  }
  getAuthId(): string | number {
    if (typeof this.getUserId === BASETYPE.FUNCTION) {
      const id = this.getUserId();
      if (typeof id === BASETYPE.STRING || typeof id === BASETYPE.NUMBER) {
        return id;
      } else {
        console.error(`${SDK_NAME} ${OPTIONS.USERID}: ${id} 期望 ${BASETYPE.STRING} 或 ${BASETYPE.NUMBER} 类型，但是传入 ${typeof id}`);
      }
    }
    return '';
  }

  getTransportData(data: any): ReportData {
    const info = {
      ...data,
      ...this.getAuthInfo(),
      uuid: this.uuid,
      pageUrl: getLocationHref(),
    };
    return info;
  }

  isSdkTransportUrl(targetUrl: string): boolean {
    let isSdkDsn = false;
    if (this.errorDsn && targetUrl.indexOf(this.errorDsn) !== -1) {
      isSdkDsn = true;
    }
    return isSdkDsn;
  }


  bindOptions(options: InitOptions): void {
    const { dsn, sysCode, appCode, beforeDataReport, userId, getUserId, useImgUpload } = options;

    validateOption(sysCode, OPTIONS.APIKEY, BASETYPE.STRING) && (this.sysCode = sysCode);
    validateOption(appCode, OPTIONS.APIKEY, BASETYPE.NUMBER) && (this.appCode = appCode);
    validateOption(dsn, OPTIONS.DNS, BASETYPE.STRING) && (this.errorDsn = dsn);
    validateOption(userId, OPTIONS.USERID, BASETYPE.STRING) && (this.userId = userId || '');
    validateOption(useImgUpload, OPTIONS.USEIMGUPLOAD, BASETYPE.BOOLEAN) &&
      (this.useImgUpload = useImgUpload || false);
    validateOption(beforeDataReport, OPTIONS.BEFOREDATAREPORT, BASETYPE.FUNCTION) &&
      (this.beforeDataReport = beforeDataReport);
    validateOption(getUserId, OPTIONS.GETUSERID, BASETYPE.FUNCTION) && (this.getUserId = getUserId);
  }

  /*
    1. fetch类型不上报
    2. 限制https://retcode-us-west-1.arms.aliyuncs.com/r.png和https://www.google-analytics.com/g/collect（204）不进行上报
    3. 上报前校验localStorage中是否有该条错误信息，若有，不进行上报；限制条数为100
  */
  beforeSend(data: ReportData) {
    const { fileName, columnName, message, type, xhrData } = data
    if (type === 'fetch') return false // fetch类型不上报
    const noReporteList = ['https://retcode-us-west-1.arms.aliyuncs.com/r.png', 'https://www.google-analytics.com/g/collect']
    if (data.message) {
      const arr = data.message.split('?')
      if (noReporteList.includes(arr[0])) {
        return false
      }
    }
    let errorInfo = ''
    if (['error', 'unhandledrejection'].includes(type)) {
      errorInfo = fileName + columnName + message + type
    } else if (type === 'resource') {
      errorInfo = fileName + message + type
    } else if (type === 'xhr') {
      errorInfo = fileName + message + type + xhrData?.url
    }
    const errorList = JSON.parse(localStorage.getItem('errorList') as string) || []

    if (errorList.includes(errorInfo)) {
      return false
    } else {
      // 最多保留100条
      if (errorList.length === 100) {
        errorList.shift()
      }
      errorList.push(errorInfo)
      localStorage.setItem('errorList', JSON.stringify(errorList))
    }
    return true
  }

  async send(data: ReportData) {
    // 上报前先校验
    const isNext = this.beforeSend(data)
    if (!isNext) return false
    const dsn = this.errorDsn;
    if (isEmpty(dsn)) {
      console.error(`${SDK_NAME}: 请配置${OPTIONS.DNS}`);
      return;
    }
    const result = (await this.beforePost(data)) as ReportData;
    if (isBrowserEnv && result) {

      if(result.fileName){
        if(!result.fileName.includes('http')){
          return false;
        }
      }else{
        result.fileName = location.href;
      }
      if(!result.userId){
        return false;
      }
      const value = this.beacon(dsn, result);
      if (!value) {
        return this.useImgUpload ? this.imgRequest(result, dsn) : this.xhrPost(result, dsn);
      }
    }
  }
}
export const reportData = _support.reportData || (_support.reportData = new Report());

